import React from 'react';
import '../Styles/InfoBanner.css';

const InfoBanner = () => {
    return (
        <div className="info-banner">
            <div className="info">
                <h3>100,000,000 Supply</h3>
            </div>
            <div className="info">
                <h3>5k + Community</h3>
            </div>
            <div className="info">
                <h3>100K+ Holders</h3>
            </div>
        </div>
    );
};

export default InfoBanner;
