import React from "react";
import "../Styles/ThirdSection.css";

const ThirdSection = () => {
  return (
    <div className="container3">

        <div className="align">
        <h3 className="container3-text">
          Once upon a time, in the lush and vibrant jungles of Solana, there
          were two mighty King Kongs named Kuro and Kaima. The enormous primates
          ruled over their respective territories with strength and wisdom, but
          a rumor had spread through the dense foliage that a position of
          supreme kingship was up for grabs
        </h3>

        <button className="container-3-button">Join The Kong Here</button>

        <h1 className='container2-text-2'>TOKENOMICS</h1>

        <h3 className="container3-text">
          Once upon a time, in the lush and vibrant jungles of Solana, there
          were two mighty King Kongs named Kuro and Kaima. The enormous primates
          ruled over their respective territories with strength and wisdom, but
          a rumor had spread through the dense foliage that a position of
          supreme kingship was up for grabs
        </h3>
        </div>

        
    </div>
  );
};

export default ThirdSection;
