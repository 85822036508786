import React, { useState, useEffect } from "react";
import "../Styles/FirstSection.css";
import Logo from "../Images/LOGOKINGG.png";

const FirstSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1050);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1050);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container">
      {isMobile ? (
        <div className="container-overlay">

          <div className="">
          <img src={Logo} alt="King Kong Logo" className="logo" />
          </div>
          
            <div className="title">King Kong ON SOL</div>
            <div className="subtitle">
              Kong Is The King Of All Kkos Clan. He Is Set To Rule The Whole
              Solana Ecosystem!
            </div>

            <div className="cta-section">
              <button className="buy-button">Buy $KKOS</button>

              <div className="social-icons">
                <span className="social-text">
                  <a href="https://twitter.com/KKONGonSol/">
                    <i class="fa-brands fa-x-twitter"></i>
                  </a>
                </span>
                <span className="social-text">
                  <a href="https://t.me/KKONGONSOL/">
                    <i class="fa-brands fa-telegram"></i>
                  </a>
                </span>
              </div>
            </div>
          
        </div>
      ) : (
        <div className="container-overlay">
          <div className="left-content">
            <div className="title">King Kong ON SOL</div>
            <div className="subtitle">
              Kong Is The King Of All Kkos Clan. He Is Set To Rule The Whole
              Solana Ecosystem!
            </div>

            <div className="cta-section">
              <button className="buy-button">Buy $KKOS</button>

              <div className="social-icons">
                <span className="social-text">
                  <a href="https://twitter.com/KKONGonSol/">
                    <i class="fa-brands fa-x-twitter"></i>
                  </a>
                </span>
                <span className="social-text">
                  <a href="https://t.me/KKONGONSOL/">
                    <i class="fa-brands fa-telegram"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="right-content">
            <img src={Logo} alt="King Kong Logo" className="logo" />
          </div>
        </div>
      )}
    </div>
  );
};

export default FirstSection;
