import './App.css';
import FirstSection from './Components/FirstSection';
import InfoBanner from './Components/InfoBanner';
import SecondSection from './Components/SecondSection';
import ThirdSection from './Components/ThirdSection';
// import FourthSection from './Components/FourthSection';
import FifthSection from './Components/FifthSection';

function App() {
  return (
    <div className="App">
      <FirstSection />
      <InfoBanner />
      <SecondSection />
      <ThirdSection />
      <FifthSection />
    </div>
  );
}

export default App;
