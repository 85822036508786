import React from "react";
import "../Styles/FifthSection.css";
import Logo from "../Images/LOGOKINGG.png";



const FifthSection = () => {


  return (
    <div className="footer">
      <div className="logo-box">
        <img src={Logo} alt="King Kong Logo" className="logo-2" />
      </div>

      <h2 className="footer-text">King Kong On Sol!!!</h2>
      <h2 className="footer-text">Copyright &copy;2024 KKOS</h2>
    </div>
  );
};

export default FifthSection;
