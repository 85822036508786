import React from 'react';
import '../Styles/SecondSection.css';

const SecondSection = () => {
    return (
        <div className='container2'>
            <div className="container2-overlay">
            <h1 className='container2-text'>BATTLE OF KINGS</h1>
            </div>
            
        </div>
    );
};

export default SecondSection;
